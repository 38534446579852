import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export const Testimonials = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,  // Slide changes every 2 seconds
    pauseOnHover: true,
  };

  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>What our clients say</h2>
        </div>
        <div className="row items-center content-center justify-center">
          <div className="col-md-12">
            {props.data ? (
              <Slider {...settings}>
                {props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="testimonial">
                    <div className="testimonial-image">
                      <img src={d.img} alt={d.name} />
                    </div>
                    <div className="testimonial-content">
                      <p>{d.text}</p>
                      <div className="testimonial-meta">- {d.name}</div>
                    </div>
                  </div>
                ))}
              </Slider>
            ) : (
              "loading"
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
